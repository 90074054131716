import React from 'react';

// import { GatsbyLink } from './glink';
import { Link } from 'gatsby-plugin-react-i18next';
import { i18nLinkProps } from './glink-for-i18n';

export interface GatsbyProps {
  to?: string;
}

export default function patchButtonBaseComponent<E, P>(
  BaseButtonComponent: React.ComponentType<P>,
) {
  return React.forwardRef<E, P & i18nLinkProps>((props, ref) => {
    const { to, ...buttonProps } = props;
    const component = to ? Link : undefined;

    return (
      <BaseButtonComponent
        component={component}
        ref={ref}
        to={to}
        {...(buttonProps as P)}
      />
    );
  });
}
