import { useState, useEffect } from 'react';

export interface WindowDimensions {
    width: number | null
    height: number | null
}

export default function useWindowDimensions() {

    function getWindowDimensions() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        return {
            width,
            height,
        };
    }

    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({ width: null, height: null });

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
