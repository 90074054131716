import { useState, useMemo } from 'react';
import type { CSSProperties, ReactNode, MouseEventHandler } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'
import type { PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { convLngCdCanonical } from '../../utils/i18n/conv';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { ButtonForI18n as Button } from '../../components/from-gatsby-theme-material-ui/button';
import { IconButton } from '../../components/from-gatsby-theme-material-ui/icon-button'

interface IImageArea {
    readonly medias: ReadonlyArray<Queries.CloudinaryMedia>
}

interface GalleryPageContentProps {
    next: string | undefined,
    prev: string | undefined,
}

function getSiblingIndexes(idx: number, arrLength) {
    const nextIdx = (idx + 1) < arrLength ? (idx + 1) : null;
    const prevIdx = idx !== 0 ? (idx - 1) : null;
    return [nextIdx, prevIdx];
}

const CenteringModal = styled(Modal)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

const ModaledBox = styled(Box)(({ theme }) => ({
    // display: "flex",
    // width: '100%',
    // height: '100%',
}));

const ModalTrnsButtonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const ImageBox = styled(Box)(({ theme }) => ({
    userSelect: "none"
}));

const NextPrevBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
}))

const TwitterLinkCircleButton = ({ ButtonComponent, action, children }: { ButtonComponent: typeof IconButton, action: MouseEventHandler | undefined, children: ReactNode }) => {
    return (<Box sx={{
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '36px',
        minHeight: '36px',
        margin: '12px',
        backgroundColor: 'rgba(43, 46, 64, 0.75)',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0)',
        borderWidth: '1px',
        borderRadius: '9999px',
        outlineStyle: 'none'
    }}>
        <ButtonComponent
            sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                color: 'rgb(255, 255, 255)'
            }}
            onClick={action}
        >


            {children}
        </ButtonComponent>
    </Box>
    )
}

const GatsbyImageWithViewPortFlex = ({ image }: { image: IGatsbyImageData }) => {
    const width = image.width;
    const height = image.height;
    const aspectRatio = height / width;

    const windowDimensions = useWindowDimensions();
    const viewportWidth = windowDimensions.width || 0;
    const viewportHeight = windowDimensions.height || 0;

    // WRT: with respect to
    const widthWRTOriginalWidth = width < (viewportWidth * 0.9) ? width : (viewportWidth * 0.9);
    const heightWRTOriginalWidth = widthWRTOriginalWidth * aspectRatio;

    const heightWRTOriginalHeight = height < (viewportHeight * 0.9) ? height : (viewportHeight * 0.9);
    const widthWRTOriginalHeight = heightWRTOriginalHeight / aspectRatio;

    const partialStyle: CSSProperties =
    {
        width: widthWRTOriginalWidth < widthWRTOriginalHeight ? widthWRTOriginalWidth : widthWRTOriginalHeight,
        height: widthWRTOriginalWidth < widthWRTOriginalHeight ? heightWRTOriginalWidth : heightWRTOriginalHeight,
    }

    return (
        <GatsbyImage
            image={image}
            alt={"Zoomed Image"}
            objectFit="fill"
            style={
                { ...partialStyle }
            }
        />
    )
}


const ImageArea = ({ medias }: IImageArea) => {
    const theme = useTheme();
    const mediaSmMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const mediaMdMatches = useMediaQuery(theme.breakpoints.up('md'));
    const [open, setOpen] = useState(false);
    const [imageInfo, setImageInfo] = useState<{
        nextIndex: number | null,
        prevIndex: number | null,
        image: IGatsbyImageData | null
    }>({
        nextIndex: null,
        prevIndex: null,
        image: null,
    });

    const mediaNotMaybe = useMemo(() => {
        return medias.filter(media => media?.secureUrledGatsbyImageData);
    }, [medias]);

    const colsImageList = mediaMdMatches
        ? 3
        : mediaSmMatches
            ? 2
            : 1
        ;

    const handleOpen = (imageForSet: IGatsbyImageData, nextIndex: number | null, prevIndex: number | null) => {
        setImageInfo({
            nextIndex,
            prevIndex,
            image: imageForSet
        });
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleImageChange = (type: "next" | "prev") => {

        let targetIdx: number | null = null;
        switch (type) {
            case "next":
                targetIdx = imageInfo.nextIndex;
                break;

            case "prev":
                targetIdx = imageInfo.prevIndex;
                break;

        }
        if (targetIdx === null) return;
        const [newNextIdx, newPrevIdx] = getSiblingIndexes(targetIdx, mediaNotMaybe.length);
        setImageInfo({
            prevIndex: newPrevIdx,
            nextIndex: newNextIdx,
            image: getImage(mediaNotMaybe[targetIdx].secureUrledGatsbyImageData) || null
        });
    }

    return (
        <>

            <ImageList
                cols={colsImageList}
                rowHeight={400}
            >
                {mediaNotMaybe.map((media, idx) => {
                    const singleImage = getImage(media.secureUrledGatsbyImageData);
                    const [nextIdx, prevIdx] = getSiblingIndexes(idx, mediaNotMaybe.length);
                    return (
                        <ImageListItem key={media?.id}>
                            {singleImage &&
                                <ButtonBase
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    onClick={() => handleOpen(singleImage, nextIdx, prevIdx)}
                                >
                                    <GatsbyImage
                                        image={singleImage}
                                        alt={media?.id || ""}
                                        backgroundColor={"#d7d7d7"}
                                        objectFit="cover"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </ButtonBase>
                            }
                        </ImageListItem>
                    )

                })}
            </ImageList>
            <CenteringModal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-image-zoom"
                aria-describedby="zoomed image"
                closeAfterTransition
            >
                <Fade in={open}>
                    <ModaledBox >
                        <ImageBox>
                            {imageInfo.image &&
                                <GatsbyImageWithViewPortFlex image={imageInfo.image}></GatsbyImageWithViewPortFlex>
                            }
                        </ImageBox>
                        <Box sx={{
                            position: "absolute",
                            top: 55,
                            left: 20,
                        }}>
                            <TwitterLinkCircleButton ButtonComponent={IconButton} action={handleClose}>
                                <CloseIcon></CloseIcon>
                            </TwitterLinkCircleButton>
                        </Box>
                        {(imageInfo.prevIndex !== null) &&
                            <Box sx={{
                                position: "absolute",
                                top: "50%",
                                left: 0,
                            }}>
                                <TwitterLinkCircleButton ButtonComponent={IconButton} action={() => handleImageChange("prev")}>
                                    <ArrowLeft></ArrowLeft>
                                </TwitterLinkCircleButton>

                            </Box>
                        }
                        {(imageInfo.nextIndex !== null) &&
                            <Box sx={{
                                position: "absolute",
                                top: "50%",
                                right: 0,
                            }}>
                                <TwitterLinkCircleButton ButtonComponent={IconButton} action={() => handleImageChange("next")}>
                                    <ArrowRight></ArrowRight>
                                </TwitterLinkCircleButton>

                            </Box>
                        }
                    </ModaledBox>

                </Fade>
            </CenteringModal>
        </>
    )
};

const AffiliateBanner = () => {
    const theme = useTheme();
    const mediaSmMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const affiliateInfo = mediaSmMatches
        ? ["https://join.hentai.xxx/track/MTQ1OS4xLjIuMi4wLjM4NS4wLjAuMA", <StaticImage src={"../../images/affiliate/affiliate-banner-01.gif"} alt="affiliate banner"></StaticImage>] as const
        : ["https://join.hentai.xxx/track/MTQ1OS4xLjIuMi4wLjM3OS4wLjAuMA", <StaticImage src={"../../images/affiliate/affiliate-banner-01-mobile.gif"} alt="affiliate banner"></StaticImage>] as const

    return (
        <Box textAlign={"center"}>
            <a href={affiliateInfo[0]} target="_blank" rel="nofollow external noopener">
                {affiliateInfo[1]}
            </a>
        </Box>
    )
}

const Gallery =
    ({ data, pageContext }: PageProps<Queries.CloudinaryFolderDataQuery, GalleryPageContentProps>) => {
        const { language, t } = useI18next();
        const dateFormated = moment(new Date(data.cloudinaryFolder?.dateFromFolderName || "")).locale(convLngCdCanonical(language)).format("LL");
        const medias = data.cloudinaryFolder?.cloudinaryMedias?.filter(item => item) as Queries.CloudinaryMedia[] || null;
        return (
            <>
                <GatsbySeo
                    title={t("imageOfTheDate",
                        {
                            val: moment(new Date(data.cloudinaryFolder?.dateFromFolderName || "")).toDate(),
                            formatParams: {
                                val: { year: 'numeric', month: 'long', day: 'numeric' },
                            }
                        })}
                    description={t("dateGalleryHeader", {
                        val: new Date(data.cloudinaryFolder?.dateFromFolderName || ""),
                        formatParams: {
                            val: { year: 'numeric', month: 'long', day: 'numeric' },
                        }
                    })}
                    openGraph={{
                        title: `Images of ${dateFormated}`,
                        description: `Images of ${dateFormated}`
                    }} />
                <Box mt="20px">
                    <NextPrevBox>
                        {pageContext.next ? <Button to={`/gallery/${pageContext.next}`} language={language}><ArrowLeft></ArrowLeft>next</Button> : <Box></Box>}
                        {pageContext.prev ? <Button to={`/gallery/${pageContext.prev}`} language={language}>prev<ArrowRight></ArrowRight></Button> : <Box></Box>}
                    </NextPrevBox>
                    <AffiliateBanner></AffiliateBanner>
                    <Box>
                        <Typography variant='h2' component="h2" fontSize={{ xs: 24, md: 36 }} align="center">
                            {t("dateGalleryHeader", {
                                val: new Date(data.cloudinaryFolder?.dateFromFolderName || ""),
                                formatParams: {
                                    val: { year: 'numeric', month: 'long', day: 'numeric' },
                                }
                            })}
                        </Typography>
                    </Box>
                    {medias &&
                        <ImageArea medias={medias}></ImageArea>
                    }
                    <AffiliateBanner></AffiliateBanner>

                </Box>
            </>
        );
    };

// export const Head = ({ data }: PageProps<{ cloudinaryFolder: Queries.CloudinaryFolder }>) => <Seo title={data.cloudinaryFolder.foldername} />

export const query = graphql`
    query CloudinaryFolderData($id: String,$language: String!) {
        cloudinaryFolder(id: {eq: $id}) {
            foldername
            dateFromFolderName
            cloudinaryMedias {
            secureUrledGatsbyImageData(
                placeholder: NONE
                transformations: ""
                outputPixelDensities: 1
            )
            id
            }
        }
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
            node {
                ns
                data
                language
            }
            }
        }
        localesLayout: allLocale(
            filter: {ns: {eq: "layout"}, language: {eq: $language}}
        ) {
            nodes {
            data
            language
            }
        }
    }

`

export default Gallery