import MuiButton, { ButtonProps } from '@mui/material/Button';

import patchButtonBaseComponent from './patch-base-button-components';
import patchButtonBaseComponentForI18n  from './patch-base-button-components-for-i18n';

export const Button = patchButtonBaseComponent<HTMLButtonElement, ButtonProps>(
  MuiButton,
);

export const ButtonForI18n = patchButtonBaseComponentForI18n<HTMLButtonElement, ButtonProps>(
  MuiButton,
);
